<template>
  <b-card title="Informations personnelles">
    <b-form
      @submit.prevent="tryToUpdate"
      @reset="resetForm"
    >
      <b-row>
        <b-col sm="6">
          <b-form-group>
            <b-form-input
              v-model="$v.localInfo.name.$model"
              placeholder="Nom"
              :class="{
                'is-invalid': $v.localInfo.name.$error
              }"
            />
            <div
              v-if="$v.localInfo.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.localInfo.name.required">Nom requis</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-form-input
              v-model="$v.localInfo.surname.$model"
              placeholder="Prénom (s)"
              :class="{
                'is-invalid': $v.localInfo.surname.$error
              }"
            />
            <div
              v-if="$v.localInfo.surname.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.localInfo.surname.required">Prénom(s) requis</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-form-input
              v-model="$v.localInfo.address.$model"
              placeholder="Nom"
              :class="{
                'is-invalid': $v.localInfo.address.$error
              }"
            />
            <div
              v-if="$v.localInfo.address.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.localInfo.address.required">Adresse requise.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-form-input
              v-model="$v.localInfo.profession.$model"
              placeholder="Prénom (s)"
              :class="{
                'is-invalid': $v.localInfo.profession.$error
              }"
            />
            <div
              v-if="$v.localInfo.profession.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.localInfo.profession.required">Profession requise.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              :disabled="process"
            >
              Rafraichir
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 ml-1"
              type="submit"
              :disabled="process"
            >
              <b-spinner
                v-if="process"
                small
                class="mr-1"
              />
              <span>Modifier</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  required,
} from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import user from '@/localStorage/user'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localInfo: {
        name: this.$store.getters.currentUser.first_name,
        surname: this.$store.getters.currentUser.last_name,
        phone: this.$store.getters.currentUser.phone_number,
        email: this.$store.getters.currentUser.email,
        address: this.$store.getters.currentUser.address,
        profession: this.$store.getters.currentUser.profession,
        matricule: this.$store.getters.currentUser.matricule,
        username: this.$store.getters.currentUser.username,
      },
    }
  },
  validations: {
    localInfo: {
      name: {
        required,
      },
      surname: {
        required,
      },
      address: {
        required,
      },
      profession: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      process: 'registerProcess',
      success: 'registerSuccess',
      error: 'registerError',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        if (val.valid) {
          user.set(val.data)
          setTimeout(() => {
            window.location.reload()
          }, 500)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Mise à jour du profil',
              text: val.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mise à jour du profil',
            text: 'Une erreur est survenue lors de la mise a jour.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updateUser']),
    formatter(value) {
      return value.toUpperCase()
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.updateUser({
        lastname: this.localInfo.name,
        firstname: this.localInfo.surname,
        address: this.localInfo.address,
        profession: this.localInfo.profession,
        username: this.localInfo.username,
        matricule: this.localInfo.matricule,
        phone_number: this.localInfo.phone,
        email: this.localInfo.email,
        profile_image: '',
        roles: this.$store.getters.currentUser.roles,
      })
    },
    resetForm(e) {
      e.preventDefault()
      this.localInfo = {
        name: this.$store.getters.currentUser.first_name,
        surname: this.$store.getters.currentUser.last_name,
        phone: this.$store.getters.currentUser.phone_number,
        email: this.$store.getters.currentUser.email,
        address: this.$store.getters.currentUser.address,
        profession: this.$store.getters.currentUser.profession,
        matricule: this.$store.getters.currentUser.matricule,
      }
    },
  },
}
</script>
