<template>
  <b-card>
    <b-row>
      <b-col sm="12">
        <div class="float-left">
          <b-avatar
            size="65"
            variant="primary"
            :text="avatarName"
            class="badge-minimal"
          />
        </div>
        <div class="float-right">
          <p class="user-name font-weight-bolder mb-0">
            {{ currentUser.first_name }} {{ currentUser.last_name }}
          </p>
          <p class="user-name font-weight-bolder mb-0">
            {{ currentUser.matricule }}
          </p>
          <!-- <span class="text-success font-weight-bolder">{{ currentUser.roles }}</span> -->
        </div>
      </b-col>

      <b-col sm="12">
        <table class="responsive mt-2">
          <tr class="w-100">
            <td class="w-50">
              <feather-icon
                icon="UserCheckIcon"
                size="18"
                class=""
              />
            </td>

            <td class="w-50">
              {{ currentUser.username }}
            </td>
          </tr>

          <tr class="w-100">
            <td>
              <feather-icon
                icon="MailIcon"
                size="18"
                class=""
              />
            </td>

            <td>
              {{ currentUser.email }}
            </td>
          </tr>

          <tr class="">
            <td>
              <feather-icon
                icon="PhoneIcon"
                size="18"
                class=""
              />
            </td>

            <td>
              {{ currentUser.phone_number }}
            </td>
          </tr>

          <tr class="">
            <td>
              <feather-icon
                icon="MapPinIcon"
                size="18"
                class=""
              />
            </td>

            <td>
              {{ currentUser.address }}
            </td>
          </tr>

          <tr class="">
            <td>
              <feather-icon
                icon="TargetIcon"
                size="18"
                class=""
              />
            </td>

            <td>
              {{ currentUser.profession }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentUser: this.$store.getters.currentUser,
    }
  },
  computed: {
    avatarName() {
      return (
        `${this.currentUser.first_name.charAt(0).toUpperCase()} ${this.currentUser.last_name.charAt(0).toUpperCase()}`
      )
    },
  },
  methods: {
  },
}
</script>
